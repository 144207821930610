
import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import LogIn from './Components/Auth/LogIn';
import Dashboard from './Components/Pages/Dashboard';

// Menu
import ItemsList from './Components/Pages/Menu/ItemsList';
import AddsOn from './Components/Pages/Menu/AddsOn';
import SupplierRules from './Components/Pages/Menu/SupplierRules';
import Orders from './Components/Pages/Menu/Orders';
import SliderImages from './Components/Pages/Menu/SliderImages';
import RolexEgg from './Components/Pages/Menu/RolexEgg';

// profile
import Profile from './Components/Pages/Navigation/Profile';

// Users 
import Suppliers from './Components/Pages/Users/Suppliers';
import AppUsers from './Components/Pages/Users/AppUsers';
import Clients from './Components/Pages/Users/Clients';
function App() {

	return (
        <Router >
			<Routes>
				<Route path="/" element={<LogIn/>} />

				<Route path="/dashboard" element={<Dashboard/>} />
				<Route path="/itemsList" element={<ItemsList/>} />
				<Route path="/addsOn" element={<AddsOn/>} />
				<Route path="/supplierRules" element={<SupplierRules/>} />

				<Route path="/suppliers" element={<Suppliers/>} />
				<Route path="/appUsers" element={<AppUsers/>} />
				<Route path="/clients" element={<Clients/>} />
				<Route path="/profile" element={<Profile/>} />
				<Route path="/orders" element={<Orders/>} />
				<Route path="/sliderImage" element={<SliderImages/>} />
				<Route path="/rolexEgg" element={<RolexEgg/>} />
			</Routes>
        </Router>
		
);}

export default App;
