import { APP_AUTH_TOKEN, APP_URL } from "./Env";

const BaseUrl = APP_URL; // Set App url
const Auth_Token = APP_AUTH_TOKEN; // set App Auth Token
export const AUTH_TOKEN = Auth_Token;

// User 
export const APIUserCreate = BaseUrl+"/api/chapatexs/users/create";
export const APIUserListByEmail = BaseUrl+"/api/chapatexs/list/user/by/email/";
export const APIUsersList = BaseUrl+"/api/chapatexs/users/list/all";
export const APIUserUpdate = BaseUrl+"/api/chapatexs/user/update";
export const APIUserRoleUpdate = BaseUrl+"/api/chapatexs/user/role/update";
export const APIUserUpdatePassword = BaseUrl+"/api/chapatexs/user/password/update";



// Menu
export const APIMenuCreate = BaseUrl+"/api/chapatexs/menu/create";
export const APIMenuList = BaseUrl+"/api/chapatexs/menu/list/all";
export const APIMenuFileName = BaseUrl+"/api/chapatexs/menu/image/file/";
export const APIMenuUpdateText = BaseUrl+"/api/chapatexs/menu/item/text/update";
export const APIMenuUpdateImage = BaseUrl+"/api/chapatexs/menu/item/image/update";
export const APIMenuDelete = BaseUrl+"/api/chapatexs/menu/item/delete/";

// Adds On
export const APIAddsOnCreate = BaseUrl+"/api/chapatexs/adds/on/create";
export const APIAddsOnList = BaseUrl+"/api/chapatexs/adds/on/list/all";
export const APIAddsOnFileName = BaseUrl+"/api/chapatexs/adds/on/image/file/";
export const APIAddsOnUpdateText = BaseUrl+"/api/chapatexs/adds/on/item/text/update";
export const APIAddsOnUpdateImage = BaseUrl+"/api/chapatexs/adds/on/item/image/update";
export const APIAddsOnDelete = BaseUrl+"/api/chapatexs/adds/on/item/delete/";


// Rolex Egg
export const APIRolexEggCreate = BaseUrl+"/api/chapatexs/rolex/egg/create";
export const APIRolexEggList = BaseUrl+"/api/chapatexs/rolex/egg/list/all";
export const APIRolexEggFileName = BaseUrl+"/api/chapatexs/rolex/egg/image/file/";
export const APIRolexEggUpdateText = BaseUrl+"/api/chapatexs/rolex/egg/item/text/update";
export const APIRolexEggUpdateImage = BaseUrl+"/api/chapatexs/rolex/egg/item/image/update";
export const APIRolexEggDelete = BaseUrl+"/api/chapatexs/rolex/egg/item/delete/";


// Slider 
export const APISliderCreate = BaseUrl+"/api/chapatexs/slider/create";
export const APISliderList = BaseUrl+"/api/chapatexs/slider/list/all";
export const APISliderFileName = BaseUrl+"/api/chapatexs/slider/image/file/";
export const APISliderUpdateText = BaseUrl+"/api/chapatexs/slider/item/text/update";
export const APISliderUpdateImage = BaseUrl+"/api/chapatexs/slider/item/image/update";
export const APISliderDelete = BaseUrl+"/api/chapatexs/slider/item/delete/";

// Supplier Rules 
export const APISupplierRuleCreate = BaseUrl+"/api/chapatexs/suppler/rules/create";
export const APISupplierRuleList = BaseUrl+"/api/chapatexs/suppler/rules/list/all";
export const APISupplierRuleUpdateText = BaseUrl+"/api/chapatexs/suppler/rules/update";
export const APISupplierRuleDelete = BaseUrl+"/api/chapatexs/suppler/rules/delete/";

// Suppliers 
export const APISupplierList = BaseUrl+"/api/chapatexs/supplier/list/all";
export const APISupplierCreate = BaseUrl+"/api/chapatexs/supplier/create";
export const APISupplierStatusUpdate = BaseUrl+"/api/chapatexs/supplier/status/update";
// Orders 
export const APIOrdersList = BaseUrl+"/api/chapatexs/order/list/all";

// Clients
export const APIClientsList = BaseUrl+"/api/chapatexs/clients/list/all";