

import React from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import { APISupplierCreate, APISupplierList, APISupplierStatusUpdate, AUTH_TOKEN, } from '../../Utilities/APIS';
import { ERROR_POST, INPUTS_REQUIRED } from '../../Utilities/Errors';

export default class Suppliers extends React.Component {
    
constructor(props){
    
    super(props);
    this.state = {
        

        showAlert:false,
        SupplierData:[],

        SupplierFullName:'',
        SupplierContact:'',
        SupplierEmail:'',
        SupplierLocation:'',
        SupplierPassword:'',

        SuccessPostMsg:'',
        ErrorPostMsg:'',

        ShowAlertSuccessPostMsg:false,
        ShowAlertErrorPostMsg:false,
        ShowAlertAllInputsRequired:false,

        // models 
        show:false,
        ShowDetailsModel:false,
        ShowEditTextModel:false,
        ShowEditImageModel:false,
        ShowStatusModel:false,

        ItemShowName:'',
        ItemShowContact:'',
        ItemShowEmail:'',
        ItemShowLocation:'',
        SupplierStatus:'',
        StatusId:'',
        AllInputsRequiredError:'',

        // Loading 
        ShowAxiosErrorAlert:false,
        AxiosErrorMessage:'',
        AxiosErrorResponse:'',
        AllInputsRequiredError:'',


        
    }    
}

componentDidMount(){
    this.loadSuppliers();
}

loadSuppliers  = () =>{
    const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

    axios.get(APISupplierList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({SupplierData:jsonResults})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })
}
// models
handleClose = () => {this.setState({show:false});this.resetModelShowAlerts();}
handleShow = () => {this.setState({show:true});}

handleCloseDetailsModel = () => {this.setState({ShowDetailsModel:false}); this.resetModelShowAlerts();}
handleShowDetailsModel = (fullName,contact,location,email) => {
    this.setState({ItemShowName:fullName});
    this.setState({ItemShowContact:contact});
    this.setState({ItemShowLocation:location});
    this.setState({ItemShowEmail:email});
    this.setState({ShowDetailsModel:true});
}
handleCloseStatusModel = () => {this.setState({ShowStatusModel:false});this.resetModelShowAlerts();}
handleShowStatusModel = (fullName,contact,location,email,statusId) => {
    this.setState({ItemShowName:fullName});
    this.setState({ItemShowContact:contact});
    this.setState({ItemShowLocation:location});
    this.setState({ItemShowEmail:email});
    this.setState({StatusId:statusId});
    this.setState({ShowStatusModel:true});
}

setSupplierFullName = (text) =>{this.setState({SupplierFullName:text.target.value})}
setSupplierContact = (text) =>{this.setState({SupplierContact:text.target.value})}
setSupplierEmail = (text) =>{this.setState({SupplierEmail:text.target.value})}
setSupplierLocation = (text) =>{this.setState({SupplierLocation:text.target.value})}
setSupplierPassword = (text) =>{this.setState({SupplierPassword:text.target.value})}
setSupplierStatus = (text) =>{this.setState({SupplierStatus:text.target.value})}



resetModelShowAlerts = () =>
{
    this.setState({ShowAlertSuccessPostMsg:false});
    this.setState({ShowAlertErrorPostMsg:false});
    this.setState({ShowAlertAllInputsRequired:false});
    this.setState({ItemShowImage:null});

}
postSupplier =  async () => 
    {
        let fullName = this.state.SupplierFullName;
        let contact = this.state.SupplierContact;
        let location = this.state.SupplierLocation;
        let password = this.state.SupplierPassword;
        let email = this.state.SupplierEmail;




        if (fullName.length === 0  || contact.length == 0 || location.length == 0 || password.length == 0 || email.length == 0 )
        {this.setState({ShowAlertAllInputsRequired:true});this.setState({AllInputsRequiredError:INPUTS_REQUIRED});}
        else
        {
            const formData = new FormData();
            formData.append('contact', contact);
            formData.append('location', location);
            formData.append('fullName', fullName);
            formData.append('password', password);
            formData.append('email', email);
        
            try {
                    const response = await axios.post(APISupplierCreate, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${AUTH_TOKEN}`
                        },
                    });
                    this.setState({ShowAlertSuccessPostMsg:true});
                    this.setState({ShowAlertErrorPostMsg:false});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({SuccessPostMsg:response.data});
                    this.loadSuppliers();
                } 
                catch (error) {
                    this.setState({ShowAlertErrorPostMsg:true});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({ShowAlertSuccessPostMsg:false});
                    this.setState({ErrorPostMsg:ERROR_POST+error.message})
                    }
            }
    }
changSupplierStatus =  async () => 
    {
        let status = this.state.SupplierStatus;
        let statusId = this.state.StatusId;

        if (status.length == 0)
        {this.setState({ShowAlertAllInputsRequired:true});this.setState({AllInputsRequiredError:INPUTS_REQUIRED});}
        else
        {
            const formData = new FormData();
            formData.append('status', status);
            formData.append('statusId', statusId);
        
            try {
                    const response = await axios.put(APISupplierStatusUpdate, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${AUTH_TOKEN}`
                        },
                    });
                    this.setState({ShowAlertSuccessPostMsg:true});
                    this.setState({ShowAlertErrorPostMsg:false});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({SuccessPostMsg:response.data});
                    
                    this.loadSuppliers();
                } 
                catch (error) {
                    this.setState({ShowAlertErrorPostMsg:true});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({ShowAlertSuccessPostMsg:false});
                    this.setState({ErrorPostMsg:ERROR_POST+error.message})
                    }
            }
    }


render() {
    const {show,ShowDetailsModel,ShowStatusModel,showAlert,SupplierData} =this.state;
return (
            <div  className='body-container'>
                <div className='nav-header-container'>
                    <Header/>
                </div>
                <div className='inner-body-container'>

                        <div className="alert alert-light bg-light text-light border-0 alert-dismissible" role="alert">
                            <strong className='strong-text primary-text' >Menu Listings</strong><span className='strong-text primary-text'>View</span>
                            <div className="custom-alert-btn-actions">
                                <ButtonGroup className="mb-2">
                                    <Button variant="primary" onClick={()=>this.handleShow()}>
                                        Add New Supplier
                                    </Button>
                                </ButtonGroup>
                                </div>
                        </div><br/>
                        {this.state.ShowAxiosErrorAlert ?(<>
                            <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                <p>{this.state.AllInputsRequiredError}</p>
                                <p>{this.state.AxiosErrorMessage}</p>
                                <p>{this.state.AxiosErrorResponse}</p>
                            </div>
                        </>):(<></>)}
                        <div className="row">
                            {showAlert ?(<> 
                                    <Alert variant="success" onClose={() => this.setState({showAlert: false})} dismissible>
                                        <Alert.Heading>Success </Alert.Heading>
                                        <p>{this.state.postMsg}</p>
                                    </Alert>
                                </>) :(<> </>)}
                            <div className="col-12">
                                <div className="card recent-sales overflow-auto">
                                    <div className="card-body">
                                    <table className="table table-hover"  id="table">
                                    <thead>
                                        <tr>
                                            <th className="tr-td-labels text-center"> SR.No</th>
                                            <th className="tr-td-labels text-center">Name</th>
                                            <th className="tr-td-labels text-center">Location</th>
                                            <th className="tr-td-labels text-center">Status</th>
                                            <th className="tr-td-labels text-center">Modify</th>
                                            <th className="tr-td-labels text-center">Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {SupplierData && SupplierData.map((item,index)=>(
                                            <tr key={index}>
                                            <td className="tr-td-labels text-center">{index+1}</td>
                                            <td className="tr-td-labels text-center">{item.fullName}</td>
                                            <td className="tr-td-labels text-center">{item.location}</td>
                                            <td className="tr-td-labels text-center">{item.status}</td>
                                            <td className="tr-td-labels text-center">
                                                <Button variant="dark" onClick={()=>this.handleShowDetailsModel(item.fullName,item.contact,item.location,item.email)}>
                                                    Show Item
                                                </Button>
                                            </td>
                                            <td className="tr-td-labels text-center">
                                                <Button variant="success" onClick={()=>this.handleShowStatusModel(item.fullName,item.contact,item.location,item.email,item.id)}>
                                                    Change Status
                                                </Button>
                                            </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                    </div>
                                </div>
                            </div>


                            <Modal show={show} onHide={()=>this.handleClose()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                <Modal.Title>Adding An New Item</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='my-grid-container-2-columns' >
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >Name</p>
                                                <input type="text" onChange={text=>this.setSupplierFullName (text)} className="form-control form-inputs" autoComplete="off" placeholder="Full Name"/>
                                            </div>
                                        </div>
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >Contact</p>
                                                <input type="text" onChange={text=>this.setSupplierContact (text)} className="form-control form-inputs" autoComplete="off" placeholder="Contact"/>
                                            </div>
                                        </div>
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >Location</p>
                                                <input type="text" onChange={text=>this.setSupplierLocation (text)} className="form-control form-inputs" autoComplete="off" placeholder="Location"/>
                                            </div>
                                        </div>
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >Email</p>
                                                <input type="text" onChange={text=>this.setSupplierEmail (text)} className="form-control form-inputs" autoComplete="off" placeholder="Email"/>
                                            </div>
                                        </div>
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >Password</p>
                                                <input type="password" onChange={text=>this.setSupplierPassword (text)} className="form-control form-inputs" autoComplete="off" placeholder="Password"/>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <br></br>
                                    {this.state.ShowAlertSuccessPostMsg ?(<>
                                        <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.SuccessPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertErrorPostMsg ?(<>
                                        <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.ErrorPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertAllInputsRequired ?(<>
                                        <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.AllInputsRequiredError}
                                        </div>
                                    </>):(<></>)}

                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleClose()}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={()=>this.postSupplier()}>
                                    Save Changes
                                </Button>
                                </Modal.Footer>
                            </Modal>

                            {/* Details Model   */}
                            <Modal show={ShowDetailsModel} onHide={()=>this.handleCloseDetailsModel ()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                <Modal.Title>Adds On Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                <div className='my-grid-container-2-columns' >
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >
                                                    <b>Name</b><br></br>
                                                    {this.state.ItemShowName}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >
                                                    <b>Name</b><br></br>
                                                    {this.state.ItemShowContact}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >
                                                    <b>Name</b><br></br>
                                                    {this.state.ItemShowLocation}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >
                                                    <b>Name</b><br></br>
                                                    {this.state.ItemShowEmail}
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                        
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleCloseDetailsModel()}>
                                    Close
                                </Button>
                                </Modal.Footer>
                            </Modal>

                            {/* Status Model   */}
                            <Modal show={ShowStatusModel} onHide={()=>this.handleCloseStatusModel ()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                    <Modal.Title>Chang Supplier Status</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                        <p>Are You Sure You Want to Change Supplier Status ??</p>
                                    </div>
                                    <div className='my-grid-container-2-columns' >
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >
                                                    <b>Name</b><br></br>
                                                    {this.state.ItemShowName}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >
                                                    <b>Name</b><br></br>
                                                    {this.state.ItemShowContact}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >
                                                    <b>Name</b><br></br>
                                                    {this.state.ItemShowLocation}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >
                                                    <b>Name</b><br></br>
                                                    {this.state.ItemShowEmail}
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                    <center>
                                        <div>
                                                <p className="primary-text" >Select Status</p>
                                                <select type="text" onChange={text=>this.setSupplierStatus (text)} className="form-control form-inputs" autoComplete="off" placeholder="Full Name">
                                                    <option> Select </option>
                                                    <option value="Approved">Approved</option>
                                                    <option value="Pending">Pending</option>
                                                </select>
                                            </div>
                                        </center>
                                    <br></br>
                                    {this.state.ShowAlertSuccessPostMsg ?(<>
                                        <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.SuccessPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertErrorPostMsg ?(<>
                                        <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.ErrorPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertAllInputsRequired ?(<>
                                        <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.AllInputsRequiredError}
                                        </div>
                                    </>):(<></>)}
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleCloseStatusModel ()}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={()=>this.changSupplierStatus()}>
                                    Yes Save Changes
                                </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                    {/* End col */}
            </div>
);
}}
