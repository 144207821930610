import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

const IsUserAuthenticated = () => {
    const [isAuthenticated, setIsAuthenticated] = useState("NO");
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        const user = localStorage.getItem('UserDetails');
        if (user) {
            const jsonData = JSON.parse(user)
            let isAuthenticated = jsonData.isAuthenticated;
            setIsAuthenticated(isAuthenticated);

        }
        const timeout = setTimeout(() => {
            if (isAuthenticated !== "YES") {
                setRedirect(true); // Set state to trigger redirection
            }
        }, 1000); // 1 seconds delay

        // Cleanup timeout to avoid memory leaks
        return () => clearTimeout(timeout);
    }, [isAuthenticated]);

    // Redirect to "/" after 1 seconds if not authenticated to log in
    if (redirect) {return <Navigate to="/" />;}
    
};

export default IsUserAuthenticated;
