

import React from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import { ERROR_POST, INPUTS_REQUIRED } from '../../Utilities/Errors'
import { APISupplierRuleCreate, APISupplierRuleDelete, APISupplierRuleList, APISupplierRuleUpdateText, AUTH_TOKEN } from '../../Utilities/APIS';

export default class SupplierRules extends React.Component {
    
constructor(props){
    
    super(props);
    this.state = {
        showAlert:false,
        SupplierRulesData:[],

        // Inputs 
        SupplierRuleName:'',
        SupplierRuleDescription:'',
        SuccessPostMsg:'',
        ErrorPostMsg:'',

        ShowAlertSuccessPostMsg:false,
        ShowAlertErrorPostMsg:false,
        ShowAlertAllInputsRequired:false,

        // models 
        show:false,
        ShowDetailsModel:false,
        ShowEditTextModel:false,
        ShowDeleteModel:false,

        ItemShowName:'',
        ItemShowPrice:'',
        updateId:'',
        deleteId:'',
        deleteFileName:'',
        ItemShowDescription:'',

        // Loading 
        ShowAxiosErrorAlert:false,
        AxiosErrorMessage:'',
        AxiosErrorResponse:'',
        AllInputsRequiredError:'',


        
    }    
}

componentDidMount(){
    this.loadSupplierRules();
}

loadSupplierRules  = () =>{
    const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

    axios.get(APISupplierRuleList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({SupplierRulesData:jsonResults})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })
}
// models
handleClose = () => {this.setState({show:false});this.resetModelShowAlerts();}
handleShow = () => {this.setState({show:true});}

handleCloseDetailsModel = () => {this.setState({ShowDetailsModel:false}); this.resetModelShowAlerts();}
handleShowDetailsModel = (name,description) => {
    this.setState({ItemShowName:name});
    this.setState({ItemShowDescription:description});
    this.setState({ShowDetailsModel:true});
}

handleCloseEditTextModel = () => {this.setState({ShowEditTextModel:false});this.resetModelShowAlerts();}
handleShowEditTextModel = (name,description,updateId) => {
    this.setState({SupplierRuleName:name});
    this.setState({SupplierRuleDescription:description});
    this.setState({updateId:updateId})
    this.setState({ShowEditTextModel:true});
    }

handleCloseDeleteModel = () => {this.setState({ShowDeleteModel:false});this.resetModelShowAlerts();}
handleShowDeleteModel = (name,description,deleteId) => {
    this.setState({ItemShowName:name});
    this.setState({ItemShowDescription:description});
    this.setState({deleteId:deleteId}); 
    this.setState({ShowDeleteModel:true});
}

setSupplierRuleName = (text) =>{this.setState({SupplierRuleName:text.target.value})}
setSupplierRuleDescription = (text) =>{this.setState({SupplierRuleDescription:text.target.value})}



resetModelShowAlerts = () =>
{
    this.setState({ShowAlertSuccessPostMsg:false});
    this.setState({ShowAlertErrorPostMsg:false});
    this.setState({ShowAlertAllInputsRequired:false});

}
postSupplierRule =  async () => 
    {
        let name = this.state.SupplierRuleName;
        let description = this.state.SupplierRuleDescription;

        if (name.length === 0 || description.length === 0 )
        {this.setState({ShowAlertAllInputsRequired:true});this.setState({AllInputsRequiredError:INPUTS_REQUIRED});}
        else
        {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('description', description);
        
            try {
                    const response = await axios.post(APISupplierRuleCreate, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${AUTH_TOKEN}`
                        },
                    });
                    this.setState({ShowAlertSuccessPostMsg:true});
                    this.setState({ShowAlertErrorPostMsg:false});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({SuccessPostMsg:response.data});
                    this.loadSupplierRules();
                } 
                catch (error) {
                    this.setState({ShowAlertErrorPostMsg:true});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({ShowAlertSuccessPostMsg:false});
                    this.setState({ErrorPostMsg:ERROR_POST+error.message})
                    }
            }
    }
updateSupplierRuleText =  async () => 
    {
        let name = this.state.SupplierRuleName;
        let description = this.state.SupplierRuleDescription;

        let updateId = this.state.updateId;

        if (name.length === 0 || description.length === 0)
        {this.setState({ShowAlertAllInputsRequired:true});this.setState({AllInputsRequiredError:INPUTS_REQUIRED});}
        else
        {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('description', description);
            formData.append('updateId', updateId);
        
            try {
                    const response = await axios.put(APISupplierRuleUpdateText , formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${AUTH_TOKEN}`
                        },
                    });
                    this.setState({ShowAlertSuccessPostMsg:true});
                    this.setState({ShowAlertErrorPostMsg:false});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({SuccessPostMsg:response.data});
                    this.loadSupplierRules();
                } 
                catch (error) {
                    this.setState({ShowAlertErrorPostMsg:true});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({ShowAlertSuccessPostMsg:false});
                    this.setState({ErrorPostMsg:ERROR_POST+error.message})
                    }
            }
    }
deleteSupplierRule =  async () => 
    {
        let deleteId = this.state.deleteId
        try {
                const response = await axios.delete(APISupplierRuleDelete+deleteId, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${AUTH_TOKEN}`
                    },
                });
                this.setState({ShowAlertSuccessPostMsg:true});
                this.setState({ShowAlertErrorPostMsg:false});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({SuccessPostMsg:response.data});
                this.loadSupplierRules();
            } 
            catch (error) {
                this.setState({ShowAlertErrorPostMsg:true});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({ShowAlertSuccessPostMsg:false});
                this.setState({ErrorPostMsg:ERROR_POST+error.message})
                }
    }
render() {
    const {show,ShowDetailsModel,ShowDeleteModel,ShowEditTextModel,showAlert,SupplierRulesData} =this.state;
return (
            <div  className='body-container'>
                <div className='nav-header-container'>
                    <Header/>
                </div>
                <div className='inner-body-container'>

                        <div className="alert alert-light bg-light text-light border-0 alert-dismissible" role="alert">
                            <strong className='strong-text primary-text' >Supplier Rules Listings</strong><span className='strong-text primary-text'>View</span>
                            <div className="custom-alert-btn-actions">
                                <ButtonGroup className="mb-2">
                                    <Button variant="primary" onClick={()=>this.handleShow()}>
                                        Add New Rule
                                    </Button>
                                </ButtonGroup>
                                </div>
                        </div><br/>
                        {this.state.ShowAxiosErrorAlert ?(<>
                            <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                <p>{this.state.AllInputsRequiredError}</p>
                                <p>{this.state.AxiosErrorMessage}</p>
                                <p>{this.state.AxiosErrorResponse}</p>
                            </div>
                        </>):(<></>)}
                        <div className="row">
                            {showAlert ?(<> 
                                    <Alert variant="success" onClose={() => this.setState({showAlert: false})} dismissible>
                                        <Alert.Heading>Success </Alert.Heading>
                                        <p>{this.state.postMsg}</p>
                                    </Alert>
                                </>) :(<> </>)}
                            <div className="col-12">
                                <div className="card recent-sales overflow-auto">
                                    <div className="card-body">
                                    <table className="table table-hover"  id="table">
                                    <thead>
                                        <tr>
                                            <th className="tr-td-labels text-center">SR.No</th>
                                            <th className="tr-td-labels text-center">Name</th>
                                            <th className="tr-td-labels text-center">Show</th>
                                            <th className="tr-td-labels text-center">Modify</th>
                                            <th className="tr-td-labels text-center">Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {SupplierRulesData && SupplierRulesData.map((item,index)=>(
                                            <tr key={index}>
                                            <td className="tr-td-labels text-center">{index+1}</td>
                                            <td className="tr-td-labels text-center">{item.name}</td>
                                            <td className="tr-td-labels text-center">
                                                <Button variant="dark" onClick={()=>this.handleShowDetailsModel(item.name,item.description)}>
                                                    Show Rule
                                                </Button>
                                            </td>
                                            <td className="tr-td-labels text-center">
                                                <Button variant="success" onClick={()=>this.handleShowEditTextModel(item.name,item.description,item.id)}>
                                                    Edit Rule
                                                </Button>
                                            </td>
                                            <td className="tr-td-labels text-center">
                                                <Button variant="danger" onClick={()=>this.handleShowDeleteModel(item.name,item.description,item.id)}>
                                                    Delete Rule
                                                </Button>
                                            </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                    </div>
                                </div>
                            </div>


                            <Modal show={show} onHide={()=>this.handleClose()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                <Modal.Title>Adding An New Rule</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='my-grid-container-1-columns' >
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >Rule</p>
                                                <input type="text" onChange={text=>this.setSupplierRuleName (text)} className="form-control form-inputs" autoComplete="off" placeholder="Rule Name"/>
                                            </div>
                                        </div>
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >Description</p>
                                                <textarea type="text" onChange={text=>this.setSupplierRuleDescription (text)} className="form-control form-inputs" autoComplete="off" placeholder="Rule Description">
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    {this.state.ShowAlertSuccessPostMsg ?(<>
                                        <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.SuccessPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertErrorPostMsg ?(<>
                                        <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.ErrorPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertAllInputsRequired ?(<>
                                        <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.AllInputsRequiredError}
                                        </div>
                                    </>):(<></>)}

                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleClose()}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={()=>this.postSupplierRule()}>
                                    Save Changes
                                </Button>
                                </Modal.Footer>
                            </Modal>

                            {/* Details Model   */}
                            <Modal show={ShowDetailsModel} onHide={()=>this.handleCloseDetailsModel ()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                <Modal.Title>Rule Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                        <div>
                                            <p className="primary-text" >
                                                <b>Rule Name</b><br></br>
                                                {this.state.ItemShowName}
                                            </p>
                                        </div>
                                        <div>
                                            <p className="primary-text" >
                                                <b>Rule Description</b><br></br>
                                                {this.state.ItemShowDescription}
                                            </p>
                                        </div>
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleCloseDetailsModel()}>
                                    Close
                                </Button>
                                </Modal.Footer>
                            </Modal>

                             {/* Edit Text Model   */}
                            <Modal show={ShowEditTextModel} onHide={()=>this.handleCloseEditTextModel ()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                    <Modal.Title>Edit Adds On</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='my-grid-container-1-columns' >
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >Rule</p>
                                                <input type="text" value={this.state.SupplierRuleName} onChange={text=>this.setSupplierRuleName (text)} className="form-control form-inputs" autoComplete="off" placeholder="Item Name"/>
                                            </div>
                                        </div>
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >Description</p>
                                                <textarea type="text" value={this.state.SupplierRuleDescription} onChange={text=>this.setSupplierRuleDescription (text)} className="form-control form-inputs" autoComplete="off" placeholder="Item Name">
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                        
                                    <br></br>
                                    {this.state.ShowAlertSuccessPostMsg ?(<>
                                        <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.SuccessPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertErrorPostMsg ?(<>
                                        <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.ErrorPostMsg}
                                        </div>
                                        </>):(<></>)}
                                    {this.state.ShowAlertAllInputsRequired ?(<>
                                    <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                        {this.state.AllInputsRequiredError}
                                    </div>
                                    </>):(<></>)}
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleCloseEditTextModel()}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={()=>this.updateSupplierRuleText()}>
                                    Save Changes
                                </Button>
                                </Modal.Footer>
                            </Modal>
                            
                            {/* Delete Model   */}
                            <Modal show={ShowDeleteModel} onHide={()=>this.handleCloseDeleteModel ()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                    <Modal.Title>Edit Adds On</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                        <p>Are You Sure You Want to Delete This Rule ??</p>
                                    </div>
                                    <div className='my-grid-container-1-columns' >
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >
                                                    <b>Rule Name</b><br></br>
                                                    {this.state.ItemShowName}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >
                                                    <b>Rule Description</b><br></br>
                                                    {this.state.ItemShowDescription}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    {this.state.ShowAlertSuccessPostMsg ?(<>
                                        <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.SuccessPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertErrorPostMsg ?(<>
                                        <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.ErrorPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertAllInputsRequired ?(<>
                                        <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.AllInputsRequiredError}
                                        </div>
                                    </>):(<></>)}
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleCloseDeleteModel ()}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={()=>this.deleteSupplierRule()}>
                                    Yes Delete Rule
                                </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                    {/* End col */}
            </div>
);
}}
